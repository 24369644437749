<template>
  <div class="divBox">
    <div class="selCard mb14">
    <el-form
      size="small"
      inline
      :model="tableFrom"
      ref="searchForm"
      label-width="110px"
    >
      <el-form-item label="快递公司名称：" prop="name">
        <el-input
          v-model="tableFrom.name"
          placeholder="请输入快递公司名称"
          class="input-with-select selWidth"
          clearable
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="small" @click="search()"
          >搜索</el-button
        >
        <el-button size="small" @click="searchReset()">重置</el-button>
      </el-form-item>
    </el-form>
    </div> 

    <el-card class="mt14">
      <el-button type="primary" @click="add" size="mini">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />

        <el-table-column prop="name" label="快递公司名称" min-width="120" />
        <el-table-column prop="code" label="快递公司编码" min-width="120" />
        <el-table-column
          prop="associationCode"
          label="关联快递公司编码"
          min-width="120"
        />
        <el-table-column label="操作" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="onDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="快递公司名称:" prop="name">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入快递公司名称"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="快递公司编码:" prop="code">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入快递公司编码"
            v-model="form.code"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联快递公司编码:" prop="associationCode">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入关联快递公司编码"
            v-model="form.associationCode"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入排序"
            v-model="form.sort"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="vLoading"
            @click="submitForm('form')"
            >提交</el-button
          >
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="选择链接"
      :visible.sync="dialogVisibleLink"
      width="700px"
      :before-close="handleCloseLink"
    >
      <GoodsCard
        v-if="dialogVisibleLink"
        @getGoodsItem="getGoodsItem"
        :storeId="form.storeId"
      />
    </el-dialog>
  </div>
</template>
<script>
import {
  expressCompanyList,
  expressCompanyUpdate,
  expressCompanyAdd,
  expressCompanyDel,
  manageView
} from "@/api/system/config";
import {
  getStoreAllList, //门店接口
} from "@/api/user/list";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import GoodsCard from "./components/goodsCard";
import { getIndex } from "@/utils/other";
export default {
  name: "banner",
  components: {
    FormImgUpload,
    GoodsCard,
  },
  data() {
    return {
      vLoading: false,
      title: "编辑快递公司",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      storeList: {},
      form: {
        name: "",
        code: "",
        associationCode: "",
        sort: "",
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        associationCode: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    /**重置 */ 
    searchReset() {
      this.tableFrom.pageNum = 1;
      this.$refs.searchForm.resetFields();
      this.getList();
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    openClick() {
      if (!this.form.storeId) {
        this.$message.error("请选择关联门店");
        return;
      }
      console.log("123", this.form.storeId);
      this.dialogVisibleLink = true;
    },
    selectChange() {
      console.log("1231");
      this.$forceUpdate();
    },
    getList() {
      this.listLoading = true;
      expressCompanyList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.title = "新增快递公司";
      this.dialogVisible = true;
    },
    onDel(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        expressCompanyDel(id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑快递公司";
      manageView(row.id).then((res) => {
        this.form = res.data;
        this.dialogVisible = true;
      });


    },
    handleClose() {
      this.form = {
        name: "",
        code: "",
        associationCode: "",
        sort: "",
      };
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.vLoading = true;
            expressCompanyUpdate({
              ...this.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.vLoading = false;
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.vLoading = false;
                // this.$message.error(res.msg);
                // done();
              }
            });
          } else {
            this.vLoading = true;
            expressCompanyAdd({
              ...this.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.vLoading = false;
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.vLoading = false;
                // this.$message.error(res.msg);
                // done();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  