<template>
  <div>
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
        <el-form-item label="商品名称：">
          <el-input
            placeholder="请输入商品名称："
            v-model="tableFrom.name"
            class="input-with-select selWidth"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
      >
        <el-table-column label="商品图" min-width="70">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.mainImg"
                :preview-src-list="[scope.row.mainImg]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="store_name" label="商品名称" min-width="200">
          <template slot-scope="scope">
            <div>
              <span
                class="tags_name"
                :style="
                  scope.row.specType == 0
                    ? 'color: #ff8a4d;'
                    : 'color: #4073FA;'
                "
                :class="'name' + scope.row.spec_type"
                >{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
              >{{ scope.row.name || "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="brandName" label="品牌名称" min-width="150" />
        <el-table-column prop="salePrice" label="商品售价" min-width="80" />
        <el-table-column prop="actualSaleNum" label="销量" min-width="70" />
        <el-table-column prop="stock" label="库存" min-width="70" />

        <el-table-column prop="sort" label="排序" min-width="60" />
      <el-table-column label="操作" min-width="150" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.cancel_time"
            type="text"
            size="small"
            @click="onDetails(scope.row)"
            >选择链接</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.page"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
    </div>
  </div>
</template>
<script>
import {
  platformStorePageList,
} from "@/api/goods/goods";
export default {
  name: "goodsCard",
  data() {
    return {
      activeName: "1",
      listLoading:false,
      tableData: {
        data: [{}],
        total: 0,
      },
      storeId:this.$store.state.user.userInfo.storeId,
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        checkStatus: "1",

      },
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    searchReset() {
      this.tableFrom.name = "";
      this.getList()
    },
    onDetails(row){
      this.$emit('getGoodsItem',row)
    },
    getList() {
      this.listLoading = true;
      platformStorePageList({...this.tableFrom,storeId:this.storeId})
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style scoped>
</style>
